import './App.scss';
import Layout from './components/Layout';
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Widget from './components/widget/Widget';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Success from './pages/Success';
import Payments from './pages/Payments';
import VerifyAppleWallet from './VerifyAppleWallet';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useQuery } from "@tanstack/react-query";
import { fetchDynamicData } from './utils/ApiHandler';

const AppContent = () => {
  let location = useLocation();

  // Extract and parse query parameters using URLSearchParams
  let queryParams = new URLSearchParams(location.search);
  let id = queryParams.get('id');
  let cancel = queryParams.get('cancel');

  const [data, setData] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const result = await fetchDynamicData("experience", id);
        setData(result);
        setIsSuccess(true);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (isSuccess && data?.data?.gtm_container_id) {
      const tagManagerArgs = {
        gtmId: data?.data?.gtm_container_id,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, [isSuccess, data]);

  return (
    <>
      <ToastContainer />
      <Layout>
        <Routes>
          <Route path={``} exact element={<Widget id={id} />} />
          <Route path={`/success`} exact element={<Success id={id} cancel={cancel} />} />
          <Route path={`/payment`} exact element={<Payments id={id} />} />
          <Route
            path={`/.well-known/apple-developer-merchantid-domain-association`}
            exact
            element={<VerifyAppleWallet />}
          />
        </Routes>
      </Layout>
    </>
  );
};

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AppContent />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
